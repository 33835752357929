import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackgroundImage from "./images/bgphone.png";
import Button from "react-bootstrap/Button";
import Job from "./images/job2.png";
import Profile1 from "./images/profile1.png";
import Profile2 from "./images/profile2.png";
import Profile3 from "./images/profile3.png";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

function App() {
  // const detectMobile = useMobileDetect() ? "mobile" : "web";
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
    ? "mobile"
    : "web";
  const navigate = useNavigate();

  return (
    <div className="App">
      <div className="logo-section">
        <Container>
          <Row>
            <Col>
              <div className={"navbar navbar-" + isMobile}>
                <a className="company-name" href="https://www.gobuildmore.com">
                  BUILDMORE
                </a>
                <div className="navbar-btns">
                  <Button
                    onClick={() => {
                      navigate("/employer");
                    }}
                    variant="link"
                    className="hire-btn"
                  >
                    Find skilled labor
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/underConstruction");
                    }}
                    variant="link"
                    className="spanish-button"
                  >
                    Español
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="subtitle">
              <div>
                <div>Get construction jobs</div>
                <Button
                  onClick={() => {
                    navigate("/underConstruction");
                  }}
                  variant="light"
                  size="lg"
                  className="worker-btn"
                >
                  Sign up to work
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <img src={BackgroundImage} alt="background" className="bg-img" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="values-section">
        <Container>
          <Row className="values">
            <Col md={{ offset: 2, span: 4 }} className="value">
              <div className="value-title">Get high paying jobs</div>
              <div>
                Discover jobs beyond your network on your terms - skills,
                location, tools. <b>Apply with one click</b>.
              </div>
              <div className="salary-title">
                💰💰 Average salary in San Francisco:
              </div>
              <div className="salary-row">
                <div className="salary">
                  <div>Carpet</div>
                  <div className="salary-number">
                    <b>$54</b>/hr
                  </div>
                </div>
                <div className="salary">
                  <div>Electrician</div>
                  <div className="salary-number">
                    <b>$83</b>/hr
                  </div>
                </div>
                <div className="salary">
                  <div>Plumber</div>
                  <div className="salary-number">
                    <b>$85</b>/hr
                  </div>
                </div>
              </div>
              <div className="salary-row">
                <div className="salary">
                  <div>Roofer</div>
                  <div className="salary-number">
                    <b>$45</b>/hr
                  </div>
                </div>
                <div className="salary">
                  <div>Tile Setter</div>
                  <div className="salary-number">
                    <b>$53</b>/hr
                  </div>
                </div>
                <div className="salary">
                  <div>Painter</div>
                  <div className="salary-number">
                    <b>$57</b>/hr
                  </div>
                </div>
              </div>
              <div className="citation">
                <span>Reference: </span>
                <a
                  target="_blank"
                  href="https://www.dir.ca.gov/oprl/2022-2/pwd/Determinations/Subtrades/jrnylist.html"
                  rel="noreferrer"
                >
                  <span className="citation-text">
                    https://www.dir.ca.gov/oprl/2022-2/pwd/Determinations/Subtrades/jrnylist.html
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md={{ offset: 1, span: 5 }}
              className={"single-mockup-" + isMobile}
            >
              <img src={Job} alt="job" className="mockup" />
            </Col>
          </Row>
          <Row className="values">
            <Col md={3} className="value">
              <div className="value-title">Get an online profile</div>
              <div>
                Let the next job find you by creating a BuildMore profile in two
                steps:
                <div className="step">1. List your skills and experience</div>
                <div className="step">2. Upload your proudest work</div>
              </div>
            </Col>
            <Col md={9}>
              <span className={"single-mockup-" + isMobile}>
                <img src={Profile1} alt="profile1" className="mockup" />
              </span>
              <span className={"single-mockup-" + isMobile}>
                <img
                  src={Profile2}
                  alt="profile2"
                  className={"mockup single-profile-" + isMobile}
                />
              </span>
              <span className={"single-mockup-" + isMobile}>
                <img src={Profile3} alt="profile3" className="mockup" />
              </span>
            </Col>
          </Row>
          <Row className="values">
            <Col md={{ offset: 3, span: 6 }} className="value single-column">
              <div className="value-title">Connect and expand your network</div>
              <div>Connect with other trades and contractors.</div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default App;
