import "./App.css";
import "./Footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

function Footer() {
  const [loading, setLoading] = useState(false);
  const [missingContact, setMissingContact] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [messageInfo, setMessageInfo] = useState({
    name: "",
    message: "",
    email: "",
    phone: "",
  });

  const onMessageInfoChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setMessageInfo({
      ...messageInfo,
      [name]: value,
    });
    setSubmitSuccess(false);
    if (messageInfo.phone || messageInfo.email) {
      setMissingContact(false);
    }
  };

  const submitMessage = () => {
    setLoading(true);
    // define the callAPI function that takes a first name and last name as parameters

    // instantiate a headers object
    var myHeaders = new Headers();
    // add content type header to object
    myHeaders.append("Content-Type", "application/json");
    // using built in JSON utility package turn object to string and store in a variable
    var raw = JSON.stringify(messageInfo);
    // create a JSON object with parameters for API call and store in a variable
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    // make API call with parameters and use promises to get response
    fetch(
      "https://9314pfg174.execute-api.us-west-1.amazonaws.com/dev",
      requestOptions
    ).then((response) => {
      setLoading(false);
      setSubmitSuccess(true);
      setMessageInfo({});
      const a = response.text();
      console.log(a);
      return a;
    });
    // .then((result) => {
    //   const resultJson = JSON.parse(result);
    //   const applicationId = resultJson.body.key;
    //   navigate("/apply", {
    //     replace: true,
    //     state: { applicationId },
    //   });
    //   setLoading(false);
    // })
    // .catch((error) => {
    //   console.log("error", error);
    //   setLoading(false);
    // });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!messageInfo.phone && !messageInfo.email) {
      setMissingContact(true);
      return;
    }
    submitMessage();
  };
  return (
    <div className="footer">
      <Container>
        <div className="contact-form">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <div>
                  Get in touch and we’ll get back to you in 24 hours. We look
                  forward to hearing from you!
                </div>
              </Col>
            </Row>
            <Row className="input-row">
              <Col md={{ span: 3, offset: 3 }}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={onMessageInfoChange}
                  name="name"
                  value={messageInfo.name}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 3, offset: 3 }} className="input-row">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="text"
                  onChange={onMessageInfoChange}
                  name="phone"
                  value={messageInfo.phone}
                />
              </Col>
              <Col md="3" className="input-row">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  onChange={onMessageInfoChange}
                  name="email"
                  value={messageInfo.email}
                />
              </Col>
            </Row>
            <Row className="input-row">
              <Col md={{ span: 6, offset: 3 }}>
                <Form.Label>Message*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  rows={3}
                  onChange={onMessageInfoChange}
                  name="message"
                  value={messageInfo.message}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <div className="error-msg">
                  {missingContact ? (
                    "Please fill in either phone number or email so we can reply to your message."
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="success-msg">
                  {submitSuccess ? (
                    "We received your message! We will get back to you in 24 hours."
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="submit-button">
                  <Button variant="light" type="submit">
                    {loading && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Loading...</span>
                      </>
                    )}
                    {!loading && <span>Submit</span>}
                  </Button>
                </div>
                <div className="company-footer">2023 BuildMore</div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
