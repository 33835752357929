import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GCBackground from "./images/GCBackground.png";
import Button from "react-bootstrap/Button";
import GCProfile from "./images/GC profile.png";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

function Employer() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
    ? "mobile"
    : "web";
  const navigate = useNavigate();

  return (
    <div className="App">
      <div className="logo-section">
        <Container>
          <Row>
            <Col>
              <div className="navbar">
                <a className="company-name" href="https://www.gobuildmore.com">
                  BUILDMORE
                </a>
                <div className="navbar-btns">
                  <Button
                    onClick={() => {
                      navigate("/");
                    }}
                    variant="link"
                    className="hire-btn"
                  >
                    Seek jobs
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/underConstruction");
                    }}
                    variant="link"
                    className="spanish-button"
                  >
                    Español
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="subtitle">
              <div>
                <div>Hire skilled workers</div>
                <Button
                  onClick={() => {
                    navigate("/underConstruction");
                  }}
                  variant="light"
                  size="lg"
                  className="worker-btn"
                >
                  Sign up to hire
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <img src={GCBackground} alt="background" className="bg-img" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="values-section">
        <Container>
          <Row className="values">
            <Col md={{ offset: 2, span: 4 }} className="value">
              <div className="value-title">
                Discover trades beyond your network
              </div>
              <div>Connect with thousands of skilled trades in minutes.</div>
            </Col>
            <Col
              md={{ offset: 1, span: 5 }}
              className={"single-mockup-" + isMobile}
            >
              <img src={GCProfile} alt="gc profile" className="mockup" />
            </Col>
          </Row>
          <Row className="values">
            <Col md={{ offset: 3, span: 6 }} className="value single-column">
              <div className="value-title">Hire the right people</div>
              <div>
                Match with the right tradespeople with the right skills. One
                click to view real work photos and references so you can
                identify the right hires. No more reference calls.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
export default Employer;
