import "./App.css";
import React from "react";

function UnderConstruction() {
  return (
    <div className="under-construction">This page is under construction.</div>
  );
}

export default UnderConstruction;
